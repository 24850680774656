$theme-colors: (
	'customer-primary': #f08519,
	'customer-secondary': #1984f0,
	'employee-primary': #acce22,
	'employee-secondary': #4422ce,
	'admin-primary': #1bade4,
	'admin-secondary': #e4521b,
	'primary': #f08519,
	'secondary': #1984f0,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
